import {store} from './../redux/configureStore';
import config from './config';

const genFetch = async (url, options, query) => {
  let address;
  if (typeof url === 'string') {
    address = config.serverUrl + url;
  }
  else if (typeof url === 'object') {
    if (url.url.startsWith('http')) {
      address = url.url;
    }
    else {
      if (url.admin) {
        //
        address = `${config.serverUrl}/n-admin${url.url}`;
      }
      else {
        address = `${config.serverUrl}${url.url}`;
      }
    }
  }

  if (query) {
    address += '?';
    for (const k in query) {
      address += Object.keys(query).map(key => `${key}=${query[key]}`).join('&');
    }
  }

  return fetch(address, options)
    .then(res => {
      return res.json()
    });
};

const genOptions = (method = 'GET', body, auth = true) => {
  const options = {
    method: method.toUpperCase(),
    credentials: 'omit',
    cache: 'no-cache',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (auth) {
    const state = store.getState();
    const auth = state.auth;
    if (auth.jwt) {
      options.headers.Authorization = `Bearer ${auth.jwt}`;
    }
  }

  if (!['GET', 'DELETE'].includes(method)) {
    options.body = JSON.stringify(body);
  }

  return options;
};

/*******************************
 ************* USER ************
 *******************************/

export const getProfile = () => genFetch('/profile', genOptions());

export const getUsers = () => genFetch({ url: '/users', admin: true }, genOptions());

export const verifyEmailExists = email => genFetch(`/user/verify-email-exists/${email}`);

export const registerUser = userInfo => {
  const body = {
    ...userInfo,
  };

  return genFetch(`/register`, genOptions('post', body, false));
};

export const loginUser = ({ email, password }) => {
  const body = {
    email,
    password
  };

  return genFetch('/login', genOptions('post', body, false));
};

/*******************************
 *********** PRODUCTS **********
 *******************************/

export const getProducts = () => genFetch({ url: '/products', admin: true }, genOptions());
export const getProductById = id => genFetch({ url: `/products/${id}`, admin: true }, genOptions());

export const postProduct = body => genFetch({ url: '/products', admin: true }, genOptions('POST', body));
export const patchProduct = body => genFetch({ url: `/products/${body._id}`, admin: true }, genOptions('PATCH', body));

export const getCategories = () => genFetch({ url: '/categories', admin: false }, genOptions());
export const postCategoryOrder = (body) => genFetch({ url: '/categories/order', admin: true }, genOptions('POST', body));


/*******************************
 ************ ORDERS ***********
 *******************************/

export const getOrdersDashboard = () => genFetch({ url: '/orders/dashboard', admin: true }, genOptions());
export const getOrdersTable = (page = 1) => genFetch({ url: `/orders/table?page=${page}`, admin: true }, genOptions());
export const getOrdersExport = (startDate, endDate) => genFetch({
  url: `/orders/export?minDate=${startDate}&maxDate=${endDate}`,
  admin: true
}, genOptions());
export const getOrders = () => genFetch({ url: '/orders', admin: true }, genOptions());
export const getOrderById = (id) => genFetch({ url: `/orders/${id}`, admin: true }, genOptions());
export const patchOrder = (body) => genFetch({ url: `/orders/${body._id}`, admin: true }, genOptions('PATCH', body));
export const postChangeOrderPayment = (orderId, customerId, body) => genFetch({ url: `/orders/customer/${customerId}/order/${orderId}/payment` }, genOptions('POST', body));
export const sendOrderMail = (orderId) => genFetch({ url: `/orders/${orderId}/print` }, genOptions('GET', {}));
export const getOrderRender = async (orderId) => await (await fetch(`${config.serverUrl}/orders/${orderId}/render`)).text();
export const getPrintOrderList = () => genFetch({ url: `/process/get-print-orders/`, admin: true }, genOptions('GET'));

/*******************************
 *********** TIMESLOTS **********
 *******************************/

export const getTimeSlots = () => genFetch({ url: '/timeslots', admin: true }, genOptions());
export const patchTimeSlot = body => genFetch({ url: `/timeslots/${body._id}`, admin: true }, genOptions('PATCH', body));
export const patchTimeSlotByTime = body => genFetch({
  url: `/timeslots/${body.time}`,
  admin: true
}, genOptions('PATCH', body));
export const genTimeSlots = body => genFetch({ url: '/timeslots/generate' }, genOptions('POST', body));

/*******************************
 *********** TIMESLOTS **********
 *******************************/

export const getSettings = () => genFetch({ url: '/settings', admin: true }, genOptions());
export const patchSettings = body => genFetch({ url: `/settings`, admin: true }, genOptions('PATCH', body));

/*******************************
 *********** CONTENTS **********
 *******************************/

export const getContents = () => genFetch({ url: '/contents', admin: true }, genOptions());
export const getContentById = id => genFetch({ url: `/contents/${id}`, admin: true }, genOptions());

export const postContent = body => genFetch({ url: '/contents', admin: true }, genOptions('POST', body));
export const patchContent = body => genFetch({ url: `/contents/${body._id}`, admin: true }, genOptions('PATCH', body));


export const getServerUrl = () => config.serverUrl;
