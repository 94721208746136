const config = {
    serverUrl: 'https://api.painapp.3tdev.cloud',
    cdnUrl: 'https://cdn.painapp.3tdev.cloud',
    siteName: 'Frontoffice Pain Public',
    defaultImage: {
        _id: '5e949c8cc5768b10d7fa2d3e',
        name: '1666641147633.png',
        localPath: 'uploads/1666641147633.png',
        httpPath: 'https://cdn.painapp.3tdev.cloud/1666641147633.png'
    }
}

function getEnvVarsFromObject(config, envObject) {
    if (envObject.REACT_APP_API_URL) config.serverUrl = envObject.REACT_APP_API_URL;
    if (envObject.REACT_APP_SITE_TITLE) config.siteName = envObject.REACT_APP_SITE_TITLE;
    if (envObject.REACT_APP_CDN_URL) config.cdnUrl = envObject.REACT_APP_CDN_URL;
    config.defaultImage.httpPath = `${config.cdnUrl}/default.png`;
}

if (process && process.env) {
    getEnvVarsFromObject(config, process.env);
}

if (window) {
    getEnvVarsFromObject(config, window);
}

export function useConfig() {
    return config;
}

export default config;
