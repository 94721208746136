import React, { Component } from 'react';
import { Layout, Icon, Switch, Tooltip } from 'antd';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser, setProducts } from "../redux/actions/Actions";
import {
  getOrderRender,
  getPrintOrderList,
  getProducts,
  patchOrder
} from "../lib/serverCommunication";
import Flex from "../components/layout/Flex";
import SidebarMenu from "./SidebarMenu";
import "../scss/header.scss";

const { Header, Content } = Layout;

class DefaultLayout extends Component {

  state = {
    collapsedState: true,
    automaticPrinting: false,
    printQueueLength: 0,
  };

  timerId = null;

  toggleCollapsed = () => this.setState({ collapsedState: !this.state.collapsedState });

  componentDidMount() {
    const automaticPrinting = localStorage.getItem('automatic-printing');
    if (automaticPrinting === 'true') {
      this.setState({ automaticPrinting: true });
    }

    getProducts()
      .then(({ products, error }) => {
        if (products) {
          this.props.setProducts(products);
        }
      });
    this.timerId = setTimeout(this.onTimer, 30000);
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  setAutomaticPrinting = (bool) => {
    this.setState({ automaticPrinting: bool });
    localStorage.setItem("automatic-printing", bool ? 'true' : 'false');
  };

  onTimer = async () => {
    if (this.state.automaticPrinting && (new Date()).getHours() >= 7) {
      const orders = await getPrintOrderList();
      if (orders.orders && orders.orders.length) {
        await this.printOrder(orders.orders[0]._id);
        this.setState({ printQueueLength: orders.orders.length - 1 });
      }
      else {
        this.setState({ printQueueLength: 0 });
      }
    }
    this.timerId = setTimeout(this.onTimer, 20000);
  };

  printOrder = async (id) => {
    const printContainer = document.createElement('div');
    try {
      const getContent = await getOrderRender(id);
      const body = document.getElementsByTagName('body')[0];
      printContainer.id = "print-container";
      printContainer.innerHTML = getContent;
      body.appendChild(printContainer);
      window.print();
      setTimeout(async () => {
        window.print();
        printContainer.remove();
        await patchOrder({ _id: id, directPrint: new Date() });
      }, 2000);
    }
    catch {
      if (printContainer) {
        printContainer.remove();
      }
    }
  };

  render() {
    const { collapsedState, automaticPrinting, printQueueLength } = this.state;
    const { toggleCollapsed } = this;

    return (
      <Layout>
        <SidebarMenu collapsed={collapsedState} toggleCollapse={toggleCollapsed}
          verifying={this.props.verifying}/>
        <Layout style={{ marginLeft: collapsedState ? 80 : 200 }}>
          <Header style={{ background: '#fff', padding: 0 }}>
            <Flex row alignItems={'center'}>
              <Flex>
                <Icon
                  className="trigger"
                  type={collapsedState ? 'menu-unfold' : 'menu-fold'}
                  onClick={toggleCollapsed}
                />
              </Flex>
              <Flex grow={1}>
                <Switch checkedChildren="Automatisch printen" unCheckedChildren="Niet automatisch printen"
                  style={{ marginRight: 10 }}
                  checked={automaticPrinting}
                  onChange={(bool) => this.setAutomaticPrinting(bool)}
                />
                {automaticPrinting && <Tooltip title={`${printQueueLength} bestellingen in wachtrij`}>
                  <Icon type="printer" data-count={printQueueLength} className="header-print"/>
                </Tooltip>}
              </Flex>
              <Flex justifyContent={'flex-end'}>
                <Icon
                  className="trigger"
                  type={'logout'}
                  onClick={this.props.logout}
                />
              </Flex>
            </Flex>

          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              background: '#fff',
            }}
          >
            {this.props.children}
          </Content>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setProducts: products => dispatch(setProducts(products)),
  logout: () => dispatch(logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DefaultLayout));
