import React, { Component, Fragment } from 'react';
import { Typography, Button, Icon, Table } from 'antd';
import { withRouter } from "react-router-dom";
import { getContents } from "../lib/serverCommunication";
import Flex from "../components/layout/Flex";
import 'react-quill/dist/quill.snow.css';

const { Title } = Typography;

class ScreensPage extends Component {

  state = {
    html: '',
    contents: [],
    filter: ''
  };

  componentDidMount() {
    this.refreshState();
  }

  refreshState = () => {
    getContents()
      .then(({ contents, error }) => {
        if (contents) {
          contents.reverse();
          this.setState({ contents });
        }
      });
  };

  render() {
    const { contents, filter } = this.state;

    const columns = [
      {
        title: 'Titel',
        dataIndex: 'title',
        key: 'title',
        sorter: (a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          else if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          else {
            return 0;
          }
        },
      }, {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        filters: [{ text: 'Online', value: 'published' }, { text: 'Niet online', value: 'draft' }],
        onFilter: (value, record) => record.status === value,
        render: (text, record) => {
          if (text === 'published') {
            return <Icon type={'check'} style={{ color: '#52c41a' }}/>
          }
          else {
            return <Icon type={'close'} style={{ color: '#eb2f96' }}/>
          }
        }
      }, {
        title: 'Wijzigen',
        render: (text, record) => {
          return (
            <Button type='seconday' icon={'edit'} onClick={() => this.props.history.push(`screens/${record._id}`)}/>
          )
        }
      }
    ];

    return (
      <Fragment>
        <Flex row justifyContent={'space-between'}>
          <Title>Contentschermen</Title>
          <Button type='primary' shape='circle' icon='plus'
            onClick={() => this.props.history.replace('/screens/new')}
          />
        </Flex>
        <Table
          dataSource={contents.filter(prod => !filter || prod.title.toLowerCase().indexOf(filter.toLowerCase()) > -1)}
          columns={columns}
          rowKey="_id"
        />
      </Fragment>
    );
  }
}

export default withRouter(ScreensPage);
