import React from 'react';

const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="10 10 80 80"
    space="preserve" width="80" height="80">
    <polygon
      points="27.644,67.644 10,50 50,10 67.644,27.644 62.93,32.356 50,19.427 19.427,50 32.356,62.93 "></polygon>
    <polygon
      points="32.356,72.356 50,90 90,50 72.356,32.356 67.644,37.07 80.573,50 50,80.573 37.07,67.644 "></polygon>
    <rect x="35.049" y="35.049" transform="matrix(-0.7072 0.7071 -0.7071 -0.7072 120.7107 50.0049)" width="29.902"
      height="29.902"></rect>
  </svg>
);

export default Logo;
