import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  AutoComplete,
  Button,
  Card,
  Icon,
  Input,
  List,
  Modal,
  Select,
  Spin,
  Switch,
  Table,
  Typography
} from "antd";
import { withRouter } from "react-router-dom";
import Flex from "../components/layout/Flex";
import {
  getCategories,
  getProductById,
  patchProduct,
  postProduct
} from "../lib/serverCommunication";
import EditProductOptionModal from "../components/ProductEditPageComponents/EditProductOptionModal";
import { formatCurrency } from "../lib/formatter";
import ImageEditComponent from "../components/ProductEditPageComponents/ImageEditComponent";

const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const defaultState = () => ({
  newProduct: false,
  productCategories: [],
  product: { options: [] },
  productId: undefined,
  loading: false,
  saving: false,
  modalVisible: false,
  modalType: '',
  productHash: 0,
});
const getObjectHash = (obj) => {
  const json = JSON.stringify(obj);
  let hash = 0;
  for (let i = 0; i < json.length; i++) {
    let character = json.charCodeAt(i);
    hash = ((hash << 5) - hash) + character;
    hash = hash & hash;
  }
  return hash;
};

class ProductEditPage extends Component {

  state = { ...defaultState() };

  componentDidMount() {
    const { match: { params } } = this.props;
    if (!params.id) {
      this.setState({ ...defaultState(), newProduct: true, });
      this.refreshState();
    }
    else {
      this.setState({ productId: params.id });
      this.loadProduct(params.id);
      this.refreshState();
    }

  }

  refreshState = () => {
    getCategories()
      .then(({ products, error }) => {
        if (products) {
          const productCategories = products.sort((a, b) => {
            return (b.order > a.order ? -1 : 1);
          });
          this.setState({ productCategories });
        }
      });
  };

  static getDerivedStateFromProps(props, state) {
    const { match: { params } } = props;
    if (params.id !== state.productId) {
      if (!params.id) {
        return { ...defaultState(), newProduct: true };
      }
      else {
        return { ...defaultState(), newProduct: false, productId: params.id }
      }
    }
    return {};
  }

  componentDidUpdate(prevProps, prevState) {
    const { newProduct, productId, product } = this.state;
    if (!newProduct && product && productId !== product._id) {
      this.loadProduct();
    }
  }

  componentWillUnmount() {
    const { product, productHash } = this.state;
    if (product && productHash !== getObjectHash(product)) {
      if (window.confirm('U heeft wijzigingen die niet opgeslagen zijn. Wilt u deze wijzigingen opslaan?')) {
        return this.saveProduct();
      }
    }
  }

  setModalVisible = (bool, type) => this.setState({ modalVisible: bool, modalType: type });

  handleUpdateProductOptions = options => this.handleValueChange('options')(options);

  handleInputChange = field => ({ target: { value } }) => {
    const { product } = this.state;
    product[field] = value;
    this.setState({ product, changes: true });
  };

  handleValueChange = field => value => {
    const { product, productCategories } = this.state;
    if (field !== 'category') {
      product[field] = value;
    }
    else {
      product.category = productCategories.find(c => c._id === value);
    }

    this.setState({ product });
  };

  loadProduct = (id) => {
    getProductById(this.state.productId || id)
      .then(({ products, error }) => {
        if (error) {
          return; //TODO: Do something with this error
        }
        if (products) {
          return this.setState({ product: products, productHash: getObjectHash(products) });
        }
      })
  };

  duplicateProduct = () => {
    if (window.confirm('Wilt u een kopie van dit product maken?')) {
      this.saveProduct(true, `${this.state.product.name} - Kopie`, 'draft')
    }
  };

  saveProduct = (forceNew = false, nameOverwrite = '', statusOverwrite = '') => {
    const { product } = this.state;
    if (
      !product.name ||
      !product.category ||
      !product.basePrice ||
      !product.description
    ) {
      return alert('Niet alle velden zijn ingevuld');
    }

    this.setState({ loading: true, saving: true, productHash: getObjectHash(product) });

    const prod = { ...product, ...(nameOverwrite ? { name: nameOverwrite } : {}), ...(statusOverwrite ? { status: statusOverwrite } : {}) };
    if (forceNew) {
      prod._id = undefined;
    }

    const promise = this.state.newProduct || forceNew ? postProduct(prod) : patchProduct(prod);

    promise
      .then(({ products, error }) => {
        if (products) {
          if (this.state.newProduct || forceNew) {
            this.props.history.push(`/products/${products._id}`);
            this.setState({ newProduct: false });
          }
        }
      })
      .finally(() => {
        setTimeout(() => {
          this.setState({ loading: false });
        }, 500);
        setTimeout(() => {
          this.setState({ saving: false });
        }, 2500);
      })
  };

  render() {
    const { newProduct, product, loading, saving, modalType, modalVisible, productCategories } = this.state;

    if (!product) {
      return <Fragment/>
    }

    const cardStyle = {
      flexGrow: 1,
      flexBasis: 'calc(40% - 40px)',
      marginBottom: 10,
      marginLeft: 20,
      marginRight: 20,
      display: 'flex',
      flexDirection: 'column',
    };

    const cardHeadStyle = {
      display: 'flex',
      alignItems: 'center',
    };

    const cardBodyStyle = {
      width: '100%', height: '100%', display: 'flex'
    };

    return (
      <Fragment>
        <Modal
          title={loading && saving ? 'Bezig met opslaan...' : 'Opgeslagen'}
          footer={<Fragment/>}
          visible={saving}
          centered
        >
          <Flex center>
            {loading && saving ?
              <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin/>}/>
              :
              <Text>Klaar</Text>
            }
          </Flex>
        </Modal>
        <EditProductOptionModal
          modalVisible={modalVisible} type={modalType} setModalVisible={this.setModalVisible}
          productOptions={product.options}
          updateProductOptions={this.handleUpdateProductOptions}
        />
        <Flex row justifyContent={'space-between'}>
          <Flex row>
            <Button type='primary' shape='circle' icon={'arrow-left'} style={{ marginRight: 10, marginTop: 8 }}
              onClick={() => this.props.history.push('/products')}
            />
            <Title>Producten {newProduct ? 'toevoegen' : 'wijzigen'}</Title>
          </Flex>
          <div>
            <Button type='primary' shape='circle' icon={'copy'} style={{ marginRight: 10 }}
              loading={loading}
              onClick={this.duplicateProduct}
            />
            <Switch checkedChildren="Online  " unCheckedChildren="Offline" style={{ marginRight: 10 }}
              checked={product.status === 'published'}
              onChange={(bool) => this.handleValueChange('status')(bool ? 'published' : 'draft')}
            />
            <Button type='primary' shape='circle' icon={newProduct ? 'plus' : 'save'}
              loading={loading}
              onClick={() => this.saveProduct()}
            />
          </div>
        </Flex>
        <Flex row style={{ width: '100%' }} wrap>
          <Flex row style={{ width: '100%' }} wrap>
            <Flex row justifyContent={'space-between'} style={{ width: '100%' }} wrap
              basis={'60%'}
              grow={1}
            >
              <Card title="Naam*"
                headStyle={cardHeadStyle}
                style={cardStyle}
                bodyStyle={cardBodyStyle}
              >
                <Input placeholder={'Productnaam'} value={product.name}
                  onChange={this.handleInputChange('name')}/>
              </Card>
              <Card title="Categorie*"
                style={{
                  ...cardStyle,
                  minWidth: 300,
                  marginBottom: 10,
                  flexGrow: 1,
                  marginLeft: 20,
                  marginRight: 20
                }}
                headStyle={cardHeadStyle}
                bodyStyle={cardBodyStyle}
              >
                <AutoComplete
                  placeholder={'Categorie'}
                  dataSource={productCategories.map(c => ({ value: c._id, text: c.name }))}
                  value={product.category ? product.category._id : undefined}
                  onChange={this.handleValueChange('category')}
                  filterOption={(inputValue, option) =>
                    option.props.children.toLowerCase().indexOf(inputValue.toLowerCase() !== -1)
                  }/>
              </Card>
              <Card title="Voorraad*"
                headStyle={cardHeadStyle}
                style={cardStyle}
                bodyStyle={cardBodyStyle}
              >
                <Select placeholder="Op voorraad" value={product.inStock}
                  style={{ width: '100%' }}
                  onChange={this.handleValueChange('inStock')}
                >
                  <Option value={1}>Op voorraad</Option>
                  <Option value={2}>Niet op voorraad</Option>
                  <Option value={9}>Blijvend uit voorraad</Option>
                </Select>
              </Card>
              <Card title="Beschrijving"
                headStyle={cardHeadStyle}
                style={cardStyle}
                bodyStyle={cardBodyStyle}
              >
                <TextArea
                  value={product.description}
                  onChange={this.handleInputChange('description')}
                  placeholder={'Product beschrijving'}
                  autoSize={{ minRows: 2, maxRows: 2 }}
                />
              </Card>
              <Card title="Prijs*"
                headStyle={cardHeadStyle}
                style={cardStyle}
                bodyStyle={cardBodyStyle}
              >
                <Input addonBefore={'€'}
                  type={'number'}
                  min={0}
                  step={0.1}
                  value={product.basePrice}
                  onChange={this.handleInputChange('basePrice')}
                />
              </Card>
              <Card title="Aanbieding"
                className={'card-flex'}
                extra={
                  <Button type='primary' shape='circle' icon={'delete'}
                    onClick={() => this.handleValueChange('salePrice')(null)}
                  />
                }
                headStyle={cardHeadStyle}
                style={cardStyle}
                bodyStyle={cardBodyStyle}
              >
                <Input addonBefore={'€'}
                  type={'number'}
                  min={0}
                  step={0.1}
                  value={product.salePrice}
                  onChange={this.handleInputChange('salePrice')}
                />
              </Card>
              <Card title="BTW Tarief"
                className={'card-flex'}
                headStyle={cardHeadStyle}
                style={cardStyle}
                bodyStyle={cardBodyStyle}
              >
                <Input addonAfter={'%'}
                  type={'number'}
                  min={0}
                  step={1}
                  value={product.vatRate}
                  onChange={this.handleInputChange('vatRate')}
                />
              </Card>
              <Card title="Inkoopprijs"
                className={'card-flex'}
                headStyle={cardHeadStyle}
                style={cardStyle}
                bodyStyle={cardBodyStyle}
              >
                <Input addonBefore={'€'}
                  type={'number'}
                  min={0}
                  step={0.1}
                  value={product.purchasePrice}
                  onChange={this.handleInputChange('purchasePrice')}
                />
              </Card>
            </Flex>
            <Flex column grow={1} basis={'30%'}>
              <Flex grow={2}>
                <Card title="Afbeelding*"
                  headStyle={cardHeadStyle}
                  style={cardStyle}
                  bodyStyle={cardBodyStyle}
                >
                  <ImageEditComponent value={product.photo}
                    onChange={(photo) => this.handleValueChange('photo')(photo.files)}/>
                </Card>
              </Flex>
              <Flex grow={1}>
                <Card title="Allergenen"
                  headStyle={cardHeadStyle}
                  style={cardStyle}
                  bodyStyle={cardBodyStyle}
                >
                  <TextArea
                    value={product.allergens}
                    onChange={this.handleInputChange('allergens')}
                    placeholder={'Product allergenen'}
                    autoSize={{ minRows: 2, maxRows: 2 }}
                  />
                </Card>
              </Flex>
            </Flex>
          </Flex>
          <Flex row justifyContent={'space-between'} style={{ width: '100%' }} wrap>

            <Card title="Ja/Nee Optie"
              className={'card-flex'}
              extra={
                <Button type='primary' shape='circle' icon={'edit'}
                  // loading={loading}
                  onClick={() => this.setModalVisible(true, 'checkbox')}
                />
              }
              headStyle={cardHeadStyle}
              style={cardStyle}
              bodyStyle={cardBodyStyle}
            >
              <Table
                style={{ width: '100%' }}
                dataSource={product.options.filter(opt => opt.type === 'checkbox')}
                className="no-highlight"
                columns={[{
                  title: 'Naam',
                  dateIndex: 'text.text',
                  key: 'text.text',
                  render: (text, record) => {
                    return record.text;
                  }
                }, {
                  title: 'Standaard',
                  dataIndex: 'default',
                  key: 'default',
                  render: (text, record) => {
                    if (record.default) {
                      return 'Ja'
                    }
                    else {
                      return 'Nee'
                    }
                  }
                }]}
              />
            </Card>
            <Card title="Keuze opties"
              className={'card-flex'}
              extra={
                <Button type='primary' shape='circle' icon={'edit'}
                  // loading={loading}
                  onClick={() => this.setModalVisible(true, 'select')}
                />
              }
              headStyle={cardHeadStyle}
              style={cardStyle}
              bodyStyle={cardBodyStyle}
            >
              <Table
                style={{ width: '100%' }}
                dataSource={product.options.filter(opt => opt.type === 'select')}
                className="no-highlight"
                columns={[{
                  title: 'Naam',
                  dateIndex: 'text.text',
                  key: 'text.text',
                  render: (text, record) => {
                    return record.text;
                  }
                }, {
                  title: 'Aantal opties',
                  dataIndex: 'default',
                  key: 'default',
                  render: (text, record) => record.options.length,
                }]}
                expandedRowRender={record => {
                  return (
                    <List
                      bordered
                      header={(
                        <Flex row justifyContent={'space-between'} style={{ width: '100%' }}>
                          <Text strong>Naam</Text>
                          <Text strong>Prijs</Text>
                        </Flex>
                      )}
                      dataSource={record.options}
                      renderItem={opt => {
                        return (
                          <List.Item>
                            <Flex row justifyContent={'space-between'} grow={1}>
                              <Flex column grow={3}>
                                <Text>{opt.text}</Text>
                              </Flex>
                              <Flex column>
                                <Text>{formatCurrency(opt.price)}</Text>
                              </Flex>
                            </Flex>
                          </List.Item>
                        )
                      }}
                    />
                  )
                }}
              />
            </Card>
          </Flex>
        </Flex>
      </Fragment>
    );
  }
}


const mapStateToProps = state => ({
  products: state.product.list,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductEditPage));
