import React, { Fragment } from 'react';
import { Button, Modal, Typography } from "antd";
import Flex from "../layout/Flex";

const { Title, Text } = Typography;

const MainPageOrderModal = ({ order, visible, handleClose }) => {

  if (!order.timeSlot) {
    return (
      <Fragment/>
    )
  }

  const otherTimeOptions = [];
  let lastTime = order.timeSlot;
  for (let i = 0; i < 6; i++) {
    const option = lastTime.clone().add(15, 'minutes');
    otherTimeOptions.push(option);
    lastTime = option;
  }

  return (
    <Modal
      title={`#${order.orderNr} | ${order.timeSlot.format('HH:mm')}`}
      centered
      visible={visible}
      footer={(
        <Button type={'primary'} onClick={handleClose}>Sluiten</Button>
      )}
      onCancel={handleClose}
    >
      <Flex row>
        <Flex column>
          <Flex grow={1} column>
            <Title level={3}>Bestelling gegevens</Title>
            <Text strong>Besteld om</Text>
            <Text>{order.date.format('HH:mm')}</Text>
            <Text strong>Voor</Text>
            <Text>
              {order.customer ? `${order.customer.firstName} ${order.customer.lastName}` : (order.userInfo ? `${order.userInfo.firstName} ${order.userInfo.lastName}` : '')}
            </Text>
          </Flex>
          <Flex grow={1} column>
            <Title level={4}>Andere tijd</Title>
            <Flex row wrap justifyContent={'space-between'}>
              {otherTimeOptions.map(option => {
                return (<Button>{option.format('HH:mm')}</Button>);
              })}
            </Flex>
          </Flex>
        </Flex>
        <Flex grow={2} column>
          <Flex column style={{ overflowY: 'auto', maxHeight: 150, width: '100%' }}>
            {
              order.products.map(product => {

                return (<Fragment key={`${product._id}-${product.productId}-${product.amount}`}>
                  <Text strong>{product.name}</Text>
                  {
                    product.options.length > 0 ?
                      <ul style={{ paddingLeft: 20 }}>
                        {product.options.map(option => {
                          //TODO: What is this ones purpose?
                          return (<Fragment/>) // Fragment because I don't know what's supposed to be here
                        })}
                      </ul>
                      : <Fragment/>
                  }
                </Fragment>);
              })
            }
          </Flex>
        </Flex>

      </Flex>

    </Modal>
  )
};

export default MainPageOrderModal;
