import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Table, Typography } from 'antd';
import { getUsers } from "../lib/serverCommunication";
import Flex from "../components/layout/Flex";

const { Title } = Typography;

class UsersPage extends Component {

  state = {
    users: [],
  };

  componentDidMount() {
    this.refreshState();
  }

  refreshState = () => {
    getUsers()
      .then(({ user, error }) => {
        if (user) {
          this.setState({ users: user });
        }
      });
  };

  render() {
    const data = this.state.users.map(user => ({
      ...user,
      name: user.firstname || '-',
    }));

    const columns = [
      {
        title: 'Naam',
        dataIndex: 'name',
        key: 'name',
      }, {
        title: 'email',
        dataIndex: 'email',
        key: 'email',
      }
    ];

    return (
      <Fragment>
        <Flex row justifyContent={'space-between'}>
          <Title>Gebruikers</Title>
        </Flex>
        <Table dataSource={data} columns={columns} rowKey="_id"/>
      </Fragment>
    );
  }
}


const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
