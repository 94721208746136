import React, { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout, Menu, Icon } from "antd";
import { Link } from "react-router-dom";
import Logo from "../assets/images/notores-logo";

const { Sider } = Layout;

const MenuItems = ({ all, currentItem, onMenuItemClick }) => {
  if (all) {
    return (
      <Menu theme="dark" mode="inline" defaultSelectedKeys={[currentItem]} onClick={onMenuItemClick}>
        <Menu.Item key="/">
          <Icon type="dashboard"/>
          <span>Home</span>
          <Link to={'/'}/>
        </Menu.Item>
        <Menu.Item key="/orders">
          <Icon type="inbox"/>
          <span>Bestellingen</span>
          <Link to={'/orders'}/>
        </Menu.Item>
        <Menu.Item key="/products">
          <Icon type="shop"/>
          <span>Producten</span>
          <Link to={'/products'}/>
        </Menu.Item>
        <Menu.Item key="/timeslots">
          <Icon type="clock-circle"/>
          <span>Tijdsvakken</span>
          <Link to={'/timeslots'}/>
        </Menu.Item>
        <Menu.Item key="/sort">
          <Icon type="filter"/>
          <span>Volgorde</span>
          <Link to={'/sort'}/>
        </Menu.Item>
        <Menu.Item key="/screens">
          <Icon type="mobile"/>
          <span>Content</span>
          <Link to={'/screens'}/>
        </Menu.Item>
        <Menu.Item key="/stats">
          <Icon type="area-chart"/>
          <span>Statistieken</span>
          <Link to={'/stats'}/>
        </Menu.Item>
        <Menu.Item key="/settings">
          <Icon type="setting"/>
          <span>Instellingen</span>
          <Link to={'/settings'}/>
        </Menu.Item>
        <Menu.Item key="/users">
          <Icon type="user"/>
          <span>Gebruikers</span>
          <Link to={'/users'}/>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <Menu theme="dark" mode="inline" defaultSelectedKeys={[currentItem]} onClick={onMenuItemClick}>
      <Menu.Item key="/">
        <Icon type="dashboard"/>
        <span>Home</span>
        <Link to={'/'}/>
      </Menu.Item>
    </Menu>
  )
};

const SidebarMenu = ({ collapsed, verifying }) => {
  let [currentItem, updateCurrentItem] = useState(useLocation().pathname || '/');

  const onMenuItemClick = ({ item, key, keyPath }) => {
    updateCurrentItem(key);
  };

  if (currentItem.indexOf('/orders/process') > -1) {
    currentItem = '/';
  }
  else if (currentItem.startsWith('/products/')) {
    currentItem = '/products';
  }

  return (
    <Fragment>
      <Sider trigger={null} collapsible collapsed={collapsed}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}>
        <div className="flex row justify-content-center logo">
          <Logo/>
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[currentItem]} onClick={onMenuItemClick}>
          <MenuItems all={!verifying} defaultSelectedKeys={[currentItem]} onClick={onMenuItemClick}/>
        </Menu>
      </Sider>
    </Fragment>
  )
};

export default SidebarMenu;
