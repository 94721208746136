import React, { Component, Fragment } from 'react';
import {
  Typography,
  Button,
  Card,
  Input,
  Checkbox
} from 'antd';
import { withRouter } from "react-router-dom";
import {
  getSettings,
  patchSettings
} from "../lib/serverCommunication";
import Flex from "../components/layout/Flex";
import 'moment/locale/nl';

const { Title, Text } = Typography;


class SettingsPage extends Component {

  state = {
    settings: {},
    saving: true,
  };

  componentDidMount() {
    this.refreshState();
  }

  refreshState = () => {
    getSettings()
      .then(({ settings, error }) => {
        if (settings) {
          delete settings.key;
          delete settings._id;
          delete settings.__v;
          delete settings.applicationSettings;
          this.setState({ settings, saving: false });
        }
      });
  };

  updateSettings = (category, settings) => {
    this.setState({ saving: true });
    patchSettings({ [category]: settings }).then(() => {
      this.refreshState();
    })
  };

  onChange = (category, key, prop, value) => {
    const newState = this.state.settings;
    const entry = newState[category].find(e => e.key === key);
    entry[prop] = value;
    this.setState({ settings: newState });
  };

  onChangeValue = (category, key, value) => {
    this.onChange(category, key, 'value', value);
  };

  renderCardContent = (category, settings) => {
    switch (category) {
    case "paymentMethods":
      return this.renderPaymentMethods(category, settings);
    case "orderHours":
    case "vatRates":
    case "businessHours":
    case "email":
    case "timeSlots":
    case "contactInfo":
    default:
      return this.renderForm(category, settings);
    }
  };

  renderPaymentMethods = (category, settings) => {
    return (
      <Flex column>
        <Flex row>
          {this.renderSectionDescription(category)}
        </Flex>
        {
          settings.map((setting, i) => (
            <Flex row wrap style={{ marginBottom: 8, padding: 8, backgroundColor: (i % 2 ? '#FFFFFF' : '#F6F6F6') }}
              justifyContent="space-between">
              <Flex column basis={"200px"} style={{ alignItems: 'center' }}>
                <Text>&nbsp;</Text>
                <Text strong>{this.renderLabelText(setting.key)}</Text>
              </Flex>
              <Flex column basis={"200px"} style={{ marginRight: 8 }}>
                <Text>Naam</Text>
                <Input value={setting.text}
                  onChange={(event) => this.onChange(category, setting.key, 'text', event.target.value)}/>
              </Flex>
              <Flex column basis={"50px"} style={{ marginRight: 8 }}>
                <Text>Actief</Text>
                <Flex center>
                  <Checkbox checked={setting.active}
                    onChange={(event) => this.onChange(category, setting.key, 'text', event.target.checked)}/>
                </Flex>
              </Flex>
              <Flex column basis={"200px"} style={{ marginRight: 8 }}>
                <Text>Prijs</Text>
                <Input value={setting.cost} type="number" min={0} max={5} step={0.1}
                  onChange={(event) => this.onChange(category, setting.key, 'cost', event.target.value)}/>
              </Flex>
              <Flex column basis={"200px"} style={{ marginRight: 8 }}>
                <Text>Volgorde</Text>
                <Input value={setting.order} type="number" min={0} max={10} step={1}
                  onChange={(event) => this.onChange(category, setting.key, 'order', event.target.value)}/>
              </Flex>
              <Flex grow={1}></Flex>
            </Flex>
          ))
        }
        <Flex>
          <Text style={{ color: 'red' }} strong>
            Let op: Zet alleen betaalmethodes aan die ook in Mollie geactiveerd zijn (m.u.v. Contant). Als een
            betaalmethode niet actief is in Mollie, kan de klant niet afrekenen!
          </Text>
        </Flex>
        <div>
          <Button type="primary" icon="save"
            onClick={() => this.updateSettings(category, this.state.settings[category])}>Opslaan</Button>
        </div>
      </Flex>
    )
  };

  renderForm = (category, settings) => {
    return (
      <Flex column>
        <Flex row>
          {this.renderSectionDescription(category)}
        </Flex>
        {
          settings.map((setting, i) => (
            <Flex row wrap style={{ marginBottom: 8, padding: 8, backgroundColor: (i % 2 ? '#FFFFFF' : '#F6F6F6') }}>
              <Flex basis={"200px"} style={{ alignItems: 'center' }}>
                <Text strong>{this.renderLabelText(setting.key)}</Text>
              </Flex>
              <Flex grow={1}>
                <Input type={setting.key.toLowerCase().indexOf('password') > -1 ? 'password' : 'text'}
                  value={setting.value}
                  onChange={(event) => this.onChangeValue(category, setting.key, event.target.value)}/>
              </Flex>
            </Flex>
          ))
        }
        <div>
          <Button type="primary" icon="save"
            onClick={() => this.updateSettings(category, this.state.settings[category])}>Opslaan</Button>
        </div>
      </Flex>
    )
  };

  renderSectionTitle = (category) => {
    const titleText = {
      paymentMethods: 'Betaalmethodes',
      email: 'E-mail',
      timeSlots: 'Tijdsvakken',
      businessHours: 'Openingstijden',
      orderHours: 'Besteltijden',
      vatRates: 'BTW Tarieven',
      contactInfo: 'Contact Informatie',
    };
    return titleText[category] || category;
  };

  renderSectionDescription = (category) => {
    const titleText = {
      paymentMethods: 'Configureer uw betaalmethodes. Let op: Zet alleen betaalmethodes aan die ook in Mollie geactiveerd zijn (m.u.v. Contant). Als een betaalmethode niet actief is in Mollie, kan de klant niet afrekenen!',
      email: '',
      timeSlots: '',
      businessHours: 'Gebruik alleen format HH:MM-HH:MM, wijk hier nooit van af!',
      orderHours: 'Gebruik alleen format HH:MM-HH:MM, wijk hier nooit van af!',
      vatRates: 'Gebruik hier alleen getallen, geen % tekens',
      contactInfo: '',
    };
    return titleText[category] || category;
  };

  renderLabelText = (key) => {
    const labelText = {
      emailFrom: 'Afzender e-mails',
      smtpServer: 'SMTP Server',
      smtpUser: 'SMTP Gebruikersnaam',
      smtpPassword: 'SMTP Wachtwoord',
      smtpPort: 'SMTP Poort',
      smtpUseSecure: 'SMTP Beveiligde verbinding?',
      printMailTo: 'Verzend orders naar E-mail',
      phone: 'Telefoonnummer',
      email: 'E-mailadres',
      address: 'Adres',
      defaultLength: 'Tijdsvak standaardduur',
    };
    return labelText[key] || key;
  };

  render() {
    const { settings } = this.state;
    const { renderCardContent, renderSectionTitle } = this;
    return (
      <Fragment>
        <Flex column justifyContent={'space-between'}>
          <Title>Instellingen</Title>
        </Flex>
        <Flex column>
          {
            Object.keys(settings).map(category => {
              if (!Array.isArray(settings[category])) {
                return null;
              }
              return (
                <Card title={renderSectionTitle(category)} style={{ marginBottom: 20 }}>
                  {renderCardContent(category, settings[category])}
                </Card>
              )
            })
          }
        </Flex>
      </Fragment>
    );
  }
}

export default withRouter(SettingsPage);
