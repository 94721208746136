import React from "react"
import { Icon } from "antd"
import './popup.css'
import moment from 'moment';

const SlotPopup = ({ slot, visible, x, y }) => visible &&
  <ul className="popup" style={{ left: `${x}px`, top: `${y}px` }}>
    <li><Icon type="clock-circle"/>{moment(slot.startTime).format('HH:mm')}</li>
    <li><Icon type="stop-o"/>{`${slot.used} / ${slot.slots}`}</li>
    <li onClick={async () => await slot.updateAvailable(1)}><Icon type="plus-o"/>Extra beschikbaar</li>
    {
      slot.slots > 0 ?
        <li onClick={async () => await slot.updateAvailable(-1)}><Icon type="minus-o"/>Minder beschikbaar</li> :
        <li></li>
    }

  </ul>;

export default SlotPopup
