import React, { useState } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "antd";
import { authenticateUser } from "../redux/actions/Actions";
import { loginUser } from "../lib/serverCommunication";
import { useConfig } from '../lib/config';
import ColorfulBGComponent from "../components/Separator/ColorfulBG.Component";

function Login(props) {
  const config = useConfig();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const onChangeHandler = fn => e => {
    if (error) {
      setError('');
    }
    fn(e.target.value);
  };

  const handleLogin = (event) => {
    if (event) {
      event.preventDefault()
    }
    setLoading(true);
    setError('');
    loginUser({
      email,
      password
    }).then(res => {
      if (res.error) {
        return setError(res.error);
      }
      if (res.user.roles.includes('admin')) {
        props.login(res);
        return props.history.replace('/');
      }
      else {
        return setError('You do not have permission to access this panel');
      }
    })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="h-full w-full">
        <div style={{
          display: 'flex',
          height: '100%',
          width: '100%',
          position: 'absolute',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: '-20',
        }} className={'flex h-2/3 w-full absolute flex justify-center items-center'}>
          <ColorfulBGComponent/>
        </div>
      </div>
      <form onSubmit={handleLogin}>
        <div className="LoginView">
          <div className="card">
            <h1>{config.siteName}</h1>
            <p className="error">{error}</p>
            <div className="login-form">
              <input
                type="email"
                name="email"
                value={email}
                onChange={onChangeHandler(setEmail)}
                placeholder="Email"
              />
            </div>
            <div className="login-form">
              <input
                type="password"
                name="password"
                value={password}
                onChange={onChangeHandler(setPassword)}
                placeholder="Wachtwoord"
              />
            </div>
            <Button type="submit" className="login-btn" onClick={handleLogin} loading={loading}>Login</Button>
          </div>
        </div>
      </form>
    </>
  )

}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  login: user => dispatch(authenticateUser(user)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
