import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Icon,
  Input,
  Table,
  Typography
} from 'antd';
import { withRouter } from "react-router-dom";
import { getProducts } from "../lib/serverCommunication";
import { formatCurrency } from "../lib/formatter";
import Flex from "../components/layout/Flex";

const { Title, Text } = Typography;

class UsersPage extends Component {

  state = {
    products: [],
    filter: ''
  };

  componentDidMount() {
    this.refreshState();
  }

  refreshState = () => {
    getProducts()
      .then(({ products, error }) => {
        products.reverse();
        if (products) {
          this.setState({ products });
        }
      });
  };

  render() {
    const { products, filter } = this.state;
    const productCategories = [...new Set(products.map(p => p.category.name))];

    const columns = [
      {
        title: 'Naam',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          else if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          else {
            return 0;
          }
        },
      }, {
        title: 'Soort',
        dataIndex: 'category.name',
        key: 'category.name',
        filters: productCategories.map(cat => ({ text: cat, value: cat })),
        onFilter: (value, record) => record.category.name === value,
      }, {
        title: 'Prijs',
        key: 'price',
        render: (text, record, index) => {
          if (record.salePrice) {
            return (
              <Text><Text
                delete>{formatCurrency(record.basePrice)}</Text> {formatCurrency(record.salePrice)}
              </Text>
            );
          }
          else {
            return formatCurrency(record.basePrice);
          }
        },
        sorter: (a, b) => {
          const priceA = a.salePrice || a.basePrice;
          const priceB = b.salePrice || b.basePrice;
          return priceA - priceB;
        },
      }, {
        title: 'Op voorraad',
        dataIndex: 'inStock',
        key: 'inStock',
        filters: [{ text: 'Op voorraad', value: 1 }, { text: 'Niet op voorraad', value: 2 }, {
          text: 'Blijvend uit voorraad',
          value: 9
        }],
        onFilter: (value, record) => record.inStock === value,
        render: (text) => {
          switch (text) {
          case 1:
            return 'Op voorraad';
          case 2:
            return 'Niet op voorraad';
          case 9:
            return 'Blijvend uit voorraad';
          default:
            return '';
          }
        }
      }, {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        filters: [{ text: 'Online', value: 'published' }, { text: 'Niet online', value: 'draft' }],
        onFilter: (value, record) => record.status === value,
        render: (text, record) => {
          if (text === 'published') {
            return <Icon type={'check'} style={{ color: '#52c41a' }}/>
          }
          else {
            return <Icon type={'close'} style={{ color: '#eb2f96' }}/>
          }
        }
      }, {
        title: 'Wijzigen',
        render: (text, record) => {
          return (
            <Button type='seconday' icon={'edit'} onClick={() => this.props.history.push(`products/${record._id}`)}/>
          )
        }
      }
    ];

    return (
      <Fragment>
        <Flex row justifyContent={'space-between'}>
          <Title>Producten</Title>
          <Button type='primary' shape='circle' icon='plus'
            onClick={() => this.props.history.replace('/products/new')}
          />
        </Flex>
        <Flex row style={{ marginBottom: 8, marginTop: 8 }}>
          <Input type="text" value={filter} onChange={(event) => this.setState({ filter: event.target.value })}
            placeholder="Zoek een product ... "/>
          <Button type="primary" icon="close" onClick={() => this.setState({ filter: '' })}
            style={{ marginLeft: 4, marginRight: 4 }}/>
        </Flex>
        <Table
          dataSource={this.state.products.filter(prod => !filter || prod.name.toLowerCase().indexOf(filter.toLowerCase()) > -1)}
          columns={columns}
          rowKey="_id"
        />
      </Fragment>
    );
  }
}


const mapStateToProps = state => ({
  products: state.product.list,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsersPage));
