import React, { Component, Fragment } from 'react';
import { Button } from "antd";
import Upload from "../../components/ProductEditPageComponents/Upload/upload/Upload";
import config from "../../lib/config";

export default class ImageEditComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      editImage: !props.value,
      value: props.value || {
        _id: '',
        name: '',
        localPath: '',
        httpPath: ''
      }
    }
  }

  componentWillReceiveProps(nextProps, _) {
    if (JSON.stringify(nextProps.value) !== JSON.stringify(this.props.value)) {
      this.setState({ value: nextProps.value, editImage: !nextProps.value });
    }
  }

  render() {
    const { editImage, value } = this.state;
    const { onChange } = this.props;

    if (!editImage) {
      return <div>
        <div className="centered-image">
          <img src={value.httpPath} alt=""/>
        </div>
        <div>
          <Button size={'small'}
            onClick={() => this.setState({ editImage: true })}>
            Wijzigen
          </Button>&nbsp;
          <Button size={'small'}
            onClick={() => {
              const result = { files: config.defaultImage };
              this.setState({ value: result.files, editImage: false });
              onChange(result);
            }}>
            Verwijderen
          </Button>
        </div>
      </div>
    }
    else {
      return <Fragment>
        <Upload onChange={(result) => {
          this.setState({ value: result.files, editImage: false });
          onChange(result);
        }}/>
        <Button size={'small'}
          onClick={() => {
            const result = { files: config.defaultImage };
            this.setState({ value: result.files, editImage: false });
            onChange(result);
          }}>
          Gebruik placeholder
        </Button>
      </Fragment>
    }
  }
}
