import React, { Component } from "react";
import Dropzone from "../../../../components/ProductEditPageComponents/Upload/dropzone/Dropzone";
import Progress from "../../../../components/ProductEditPageComponents/Upload/progress/Progress";
import "./Upload.scss";
import { getServerUrl } from "../../../../lib/serverCommunication";

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
  }

  async onFilesAdded(files) {
    await this.uploadFiles(files);
  }

  async uploadFiles(files) {
    this.setState({ uploadProgress: {}, uploading: true });
    try {
      const response = await this.sendRequest(files[0]);
      this.setState({ successfullUploaded: true, uploading: false });
      this.props.onChange(response);
    }
    catch (e) {
      console.log(e);
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
    }
  }

  sendRequest(file) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.addEventListener("readystatechange", event => {
        if (req.readyState === 4) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = { state: "done", percentage: 100 };
          this.setState({ uploadProgress: copy });
          resolve(JSON.parse(req.response));
        }
      });

      req.upload.addEventListener("error", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });
        resolve(JSON.parse(req.response));
      });


      const formData = new FormData();
      formData.append("file", file, file.name);

      req.open("POST", `${getServerUrl()}/upload`);
      req.setRequestHeader('Accept', 'application/json');
      req.send(formData);
    });
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0}/>
          <img
            className="CheckIcon"
            alt="done"
            src="baseline-check_circle_outline-24px.svg"
            style={{
              opacity:
                uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
            }}
          />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="Upload">
        <div className="Content">
          {
            !(this.state.uploading || this.state.successfullUploaded) ?
              <div>
                <Dropzone
                  onFilesAdded={this.onFilesAdded}
                  disabled={this.state.uploading || this.state.successfullUploaded}
                />
              </div> :
              <div className="Files">
                {this.state.files.map(file => {
                  return (
                    <div key={file.name} className="Row">
                      <span className="Filename">{file.name}</span>
                      {this.renderProgress(file)}
                    </div>
                  );
                })}
              </div>
          }
        </div>
      </div>
    );
  }
}

export default Upload;
