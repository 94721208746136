import { combineReducers } from "redux";

import auth from './auth';
import product from './product';
import settings from './settings';

export default combineReducers({
  auth,
  product,
  settings,
})
