import React, { Component, Fragment } from 'react';
import {
  Button,
  Divider,
  Input,
  List,
  Modal,
  Switch,
  Typography
} from "antd";
import Flex from "../layout/Flex";

const { Text } = Typography;


export default class EditProductOptionModal extends Component {

  static makeKey = length => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  static generateNewIdInOptionlist = options => {
    let newKey = EditProductOptionModal.makeKey(6);
    const findSelector = opt => opt.key === newKey;
    while (options.find(findSelector)) {
      newKey = EditProductOptionModal.makeKey(6);
    }
  };

  state = {
    editingProductOptionKey: '',
  };

  closeModal = () => {
    let { productOptions } = this.props;
    productOptions = productOptions.filter(po => po.text);
    productOptions.forEach(po => {
      if (po.type === 'select') {
        po.options = po.options.filter(poo => poo.text)
      }
    });
    this.props.updateProductOptions(productOptions);

    this.props.setModalVisible(false, this.props.type);
  };

  handleInputChange = field => ({ target: { value } }) => {
    const { product } = this.state;
    product[field] = value;
    this.setState({ product });
  };

  handleValueChange = field => value => {
    const { product } = this.state;
    product[field] = value;
    this.setState({ product });
  };

  addProductOptions = () => {
    const { type } = this.props;
    const { productOptions } = this.props;
    productOptions.push(type === 'checkbox' ?
      {
        key: EditProductOptionModal.makeKey(6),
        text: '',
        type: 'checkbox',
        default: false
      } : // type === 'select'
      {
        key: EditProductOptionModal.makeKey(6),
        text: '',
        type: 'select',
        options: []
      }
    );
    this.props.updateProductOptions(productOptions);
  };

  updateProductOptions = (optionKey) => (inputType, field, value) => {
    const { productOptions } = this.props;
    const option = productOptions.find(opt => opt.key === optionKey);
    option[field] = inputType === 'input' ? value.target.value : value;
    this.props.updateProductOptions(productOptions);
  };

  removeProductOption = index => {
    const { productOptions } = this.props;
    const option = productOptions[index];

    const result = window.confirm(`U gaat "${option.text}" verwijderen. Weet U het zeker?`);
    if (result) {
      productOptions.splice(index, 1);
      this.props.updateProductOptions(productOptions);
    }
  };


  addProductOptionsOption = (optionKey) => {
    if (!optionKey) {
      return;
    }
    const { productOptions } = this.props;

    const productOption = productOptions.find(opt => opt.key === optionKey);
    productOption.options.push({
      text: "",
      price: 0
    });
    this.props.updateProductOptions(productOptions);
  };

  updateProductOptionsOption = (index) => (inputType, field, value) => {
    const { productOptions } = this.props;
    const { editingProductOptionKey } = this.state;
    const options = productOptions.find(opt => opt.key === editingProductOptionKey).options;
    options[index][field] = inputType === 'input' ? value.target.value : value;
    this.props.updateProductOptions(productOptions);
  };

  removeProductOptionsOption = index => {
    const { productOptions } = this.props;
    const { editingProductOptionKey } = this.state;
    const options = productOptions.find(opt => opt.key === editingProductOptionKey).options;
    options.splice(index, 1);
    this.props.updateProductOptions(productOptions);
  };

  renderHeader = () => {
    const { type } = this.props;
    return type === 'checkbox' ?
      (
        <Flex row justifyContent={'space-between'} style={{ width: '100%' }}>
          <Text strong>Naam</Text>
          <Text strong>Standaard</Text>
        </Flex>
      ) : (
        <Flex row justifyContent={'space-between'} style={{ width: '100%' }}>
          <Text strong>Naam</Text>
        </Flex>
      )
  };

  renderProductOptionsCheckbox = (item, index) => {
    const { type } = this.props;
    if (item.type === type) {
      return (
        <List.Item>
          <Flex row justifyContent={'space-between'} alignItems={'center'} style={{ width: '100%' }}>
            <Flex column grow={0.1}>
              <Button type={'danger'} shape="circle" icon={'delete'}
                size={'large'}
                onClick={() => this.removeProductOption(index)}/>
            </Flex>
            <Flex column grow={1}>
              <Input type={'text'} value={item.text}
                onChange={val => this.updateProductOptions(item.key)('input', 'text', val)}/>
            </Flex>
            <Flex column grow={0.1} alignItems={'flex-end'}>
              <Switch checked={item.default}
                onChange={val => this.updateProductOptions(item.key)('value', 'default', val)}/>
            </Flex>
          </Flex>
        </List.Item>
      );
    }
    else {
      return (<Fragment/>);
    }
  };

  renderProductOptionsSelect = (item, index) => {
    const { type } = this.props;
    if (item.type === type) {
      return (
        <List.Item>
          <Flex row justifyContent={'space-between'} alignItems={'center'} style={{ width: '100%' }}>
            <Flex grow={0.1}>
              <Button type={'danger'} shape="circle" icon={'delete'}
                size={'large'}
                onClick={() => this.removeProductOption(index)}/>
            </Flex>
            <Flex column grow={1}>
              <Input type={'text'} value={item.text}
                onChange={val => this.updateProductOptions(item.key)('input', 'text', val)}/>
            </Flex>
            <Flex column grow={0.1} alignItems={'flex-end'}>
              <Button
                type='primary' shape='circle' icon={'edit'}
                onClick={() => this.setState({ editingProductOptionKey: item.key })}/>
            </Flex>
          </Flex>

        </List.Item>
      );
    }
    else {
      return (<Fragment/>);
    }
  };

  render() {
    const { editingProductOptionKey } = this.state;
    const { type, productOptions, modalVisible } = this.props;

    let selectOptions = [];
    if (editingProductOptionKey && type === 'select') {
      const prod = productOptions.find(p => p.key === editingProductOptionKey) || { options: [] };
      selectOptions = prod.options || [];
    }


    return (
      <Fragment>
        <Modal
          title={`${type === 'checkbox' ? 'Ja/Nee' : 'Keuze'} opties wijzigen`}
          centered
          visible={modalVisible}
          style={{ maxheight: '60%' }}
          width={`${type === 'checkbox' ? '50%' : '75%'}`}
          onCancel={this.closeModal}
          footer={null}
        >
          <Flex row>
            <Flex column grow={type === 'checkbox' ? 1 : 2}>
              <Button type='primary'
                onClick={() => this.addProductOptions()}
              >
                Optie toevoegen
              </Button>
              <Divider/>
              <List
                header={this.renderHeader()}
                bordered
                dataSource={productOptions}
                renderItem={type === 'checkbox' ? this.renderProductOptionsCheckbox : this.renderProductOptionsSelect}
              />
            </Flex>
            <Flex hide={type === 'checkbox'} grow={1}/>
            <Flex column grow={2} hide={type === 'select' && editingProductOptionKey}/>
            <Flex column grow={2} hide={type === 'checkbox' || !editingProductOptionKey}>
              <Button type='primary'
                onClick={() => this.addProductOptionsOption(editingProductOptionKey)}
              >
                Keuze toevoegen
              </Button>
              <Divider/>
              {/*<Flex column className={'containerPadding'}>*/}
              {/*    <Flex row justifyContent={'space-between'} style={{width: '100%'}}>*/}
              {/*        <Text strong>Naam</Text>*/}
              {/*        <Text strong>Prijs</Text>*/}
              {/*    </Flex>*/}
              {/*</Flex>*/}
              <List
                bordered
                header={(
                  <Flex row justifyContent={'space-between'} style={{ width: '100%' }}>
                    <Text strong>Naam</Text>
                    <Text strong>Prijs</Text>
                  </Flex>
                )}
                dataSource={selectOptions}
                renderItem={(opt, index) => {
                  return (
                    <List.Item style={{ display: 'flex' }}>
                      <Flex row justifyContent={'space-between'} grow={1}>
                        <Flex column grow={2}>
                          <Button type={'danger'} shape="circle" icon={'delete'}
                            size={'large'}
                            onClick={() => this.removeProductOptionsOption(index)}/>
                        </Flex>
                        <Flex column grow={3}>
                          <Input
                            value={opt.text}
                            onChange={val => this.updateProductOptionsOption(index)('input', 'text', val)}
                            placeholder={'Naam'}
                          />
                        </Flex>
                        <Flex grow={1}/>
                        <Flex column grow={.5}>
                          <Input addonBefore={'€'}
                            type={'number'}
                            min={0}
                            step={0.1}
                            value={opt.price}
                            onChange={val => this.updateProductOptionsOption(index)('input', 'price', val)}
                            // onChange={this.handleInputChange('basePrice')}
                          />
                        </Flex>
                      </Flex>
                    </List.Item>
                  )
                }}
              />
            </Flex>
          </Flex>
        </Modal>
      </Fragment>
    )
    ;
  }
}
