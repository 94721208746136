import React, { Component, Fragment } from 'react';
import {
  Typography,
  Button,
  Icon,
  Spin,
  Switch,
  Card,
  Input
} from 'antd';
import { withRouter } from "react-router-dom";
import ReactQuill, { Quill } from 'react-quill';
import {
  getContentById,
  patchContent,
  postContent
} from "../lib/serverCommunication";
import Flex from "../components/layout/Flex";
import 'react-quill/dist/quill.snow.css';

const { Title } = Typography;

class ScreenPage extends Component {
  quill = null;

  state = {
    title: '',
    html: '',
    status: 'draft',
    newContent: false,
    contentId: 0,
    loading: true,
    saving: false,
    changes: false,
  };

  componentDidMount() {
    const { match: { params } } = this.props;
    if (!params.id || params.id === 'new') {
      this.setState({ newContent: true, loading: false });
    }
    else {
      this.setState({ contentId: params.id });
      this.loadContent(params.id);
    }

  }

  loadContent = (id) => {

    getContentById(id)
      .then(({ contents, error }) => {
        if (contents) {
          this.setState({ ...contents, loading: false });
        }
      });
  };

  saveContent = () => {
    const { title, html, status, newContent, contentId } = this.state;
    this.setState({ saving: true });
    if (newContent) {
      postContent({ html, title, status }).then((data) => {
        this.setState({ saving: false, changes: false, newContent: false, contentId: data.contents._id });
        window.history.pushState(null, null, '/contents/' + data.contents._id);
      })
    }
    else {
      patchContent({ _id: contentId, html, title, status }).then(() => {
        this.setState({ saving: false, changes: false });
      })
    }
  };

  handleValueChange = field => value => {
    this.setState({ [field]: value, changes: true });
  };

  handleInputChange = field => ({ target: { value } }) => {
    this.setState({ [field]: value, changes: true });
  };

  modules = {
    toolbar: {
      container: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image'],
        ['clean']
      ],
      handlers: {
        image: this.imageHandler
      }
    }
  };

  formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image'
  ];

  imageHandler = () => {
    const range = this.quill.getSelection();
    const value = prompt('What is the image URL');
    this.quill.insertEmbed(range.index, 'image', value, Quill.sources.USER);
  };

  render() {
    const { html, newContent, title, status, loading, saving, changes } = this.state;

    const cardStyle = {
      flexGrow: 1,
      flexBasis: 'calc(40% - 40px)',
      marginBottom: 10,
      marginLeft: 20,
      marginRight: 20,
      display: 'flex',
      flexDirection: 'column',
    };

    const cardHeadStyle = {
      display: 'flex',
      alignItems: 'center',
    };

    const cardBodyStyle = {
      width: '100%', height: '100%', display: 'flex'
    };
    if (loading) {
      return (
        <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin/>}/>
      );
    }

    return (
      <Fragment>
        <Flex row justifyContent={'space-between'}>
          <Flex row>
            <Button type='primary' shape='circle' icon={'arrow-left'} style={{ marginRight: 10, marginTop: 8 }}
              onClick={() => this.props.history.push('/screens')}
            />
            <Title>Contentscherm {newContent ? 'toevoegen' : 'wijzigen'}</Title>
          </Flex>
          <div>
            <Switch checkedChildren="Online  " unCheckedChildren="Offline" style={{ marginRight: 10 }}
              checked={status === 'published'}
              onChange={(bool) => this.handleValueChange('status')(bool ? 'published' : 'draft')}
            />
            <Button type='primary' shape='circle' icon={newContent ? 'plus' : 'save'}
              loading={saving}
              disabled={!changes}
              onClick={() => this.saveContent()}
            />
          </div>
        </Flex>
        <Flex column>
          <Card title="Titel*"
            headStyle={cardHeadStyle}
            style={cardStyle}
            bodyStyle={cardBodyStyle}
          >
            <Input placeholder={'Scherm titel'} value={title}
              onChange={this.handleInputChange('title')}/>
          </Card>
          <Card title="Content*"
            headStyle={cardHeadStyle}
            style={cardStyle}
            bodyStyle={{ ...cardBodyStyle, paddingBottom: 70 }}
          >
            <ReactQuill theme="snow"
              modules={this.modules}
              formats={this.formats}
              style={{ minHeight: 400, width: '100%' }}
              value={html}
              ref={(ref) => this.quill = ref}
              onChange={(content, delta, source, editor) => {
                this.handleValueChange('html')(editor.getHTML())
              }}/>
          </Card>
        </Flex>
      </Fragment>
    );
  }
}

export default withRouter(ScreenPage);
