import { SET_PRODUCTS } from "../actions/Actions";

const initialState = {
  list: [],
};

const product = (state = { ...initialState }, action) => {
  switch (action.type) {
  case SET_PRODUCTS:
    return {
      ...state,
      list: [...action.payload],
    };
  default:
    return state;
  }
};

export default product;


