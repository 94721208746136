const nlDateFormat = new Intl.DateTimeFormat('nl-NL', { year: 'numeric', month: 'short', day: '2-digit' });
const nlCurrencyFormat = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' });
const nlDateTimeFormat = new Intl.DateTimeFormat('nl-NL', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  hour12: false,
  minute: '2-digit'
});

export const formatDate = date => nlDateFormat.format(date instanceof Date ? date : new Date(date));
export const formatDateTime = date => nlDateTimeFormat.format(date instanceof Date ? date : new Date(date));
export const formatCurrency = number => nlCurrencyFormat.format(number);
