import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, DatePicker, Typography } from 'antd';
import moment from 'moment';
import { CSVLink } from "react-csv";
import { getOrdersExport } from "../lib/serverCommunication";
import Flex from "../components/layout/Flex";

const { Title } = Typography;

class UsersPage extends Component {

  state = {
    exportStartDate: moment().add(-30, 'day').startOf('day'),
    exportEndDate: moment().startOf('day'),
    exportData: [],
    separator: ';',
  };

  loadExportData = () => {
    getOrdersExport(this.state.exportStartDate.format('YYYY-MM-DD'), this.state.exportEndDate.format('YYYY-MM-DD')).then(data => {
      const orders = data.orders.map(order => {
        order.paid = false;
        order.paymentMethod = 'Bij afhalen';
        order.payment.forEach(payment => {
          if (order.paymentMethod !== 'contant') {
            order.paymentMethod = payment.method;
          }
          if (['paid', 'paidout'].includes((payment.status || '').toLowerCase())) {
            order.paid = true;
          }
        });
        const data = {
          timeSlot: moment(order.timeSlot).format('DD-MM-YYYY HH:mm'),
          date: moment(order.date).format('DD-MM-YYYY HH:mm'),
          products: order.products.reduce((sum, cur) => sum + cur.amount, 0),
          paid: order.paid ? 'Ja' : 'Nee',
          paymentMethod: order.paymentMethod,
          orderNr: order.orderNr,
          customer: (order.userInfo && order.userInfo.firstName) ? `${order.userInfo.firstName || ''} ${order.userInfo.lastName || ''}` :
            ((order.customer) ? `${order.customer.firstName || ''} ${order.customer.lastName || ''}` : ''),
          total: (Math.round(order.products.reduce((acc, cur) => {
            return acc + (cur.amount * (cur.price + cur.options.reduce((acc2, cur2) => acc2 + cur2.price, 0)))
          }, 0) * 100) / 100)
        };

        if (this.state.separator === ';') {
          data.total = data.total.toFixed(2).replace('.', ',')
        }
        return data;
      });
      this.setState({ exportData: orders });
    })
  };

  render() {

    return (
      <Fragment>
        <Flex row justifyContent={'space-between'}>
          <Title>Statistieken</Title>
        </Flex>
        <Flex column justifyContent={'space-between'}>
          <h3>Export</h3>
          <div style={{ marginBottom: 12 }}>
            Van <DatePicker value={this.state.exportStartDate}
              onChange={(date) => this.setState({ exportStartDate: date })}/>
            tot <DatePicker value={this.state.exportEndDate} onChange={(date) => this.setState({ exportEndDate: date })}/>
          </div>
          <Flex row>
            <label style={{ display: 'block', marginRight: 20 }}>Scheidingsteken:</label>
            <select onChange={(event) => {
              this.setState({ separator: event.target.value })
            }}>
              <option value=";" {...(this.state.separator === ';' ? { selected: true } : {})} >;</option>
              <option value="," {...(this.state.separator === ',' ? { selected: true } : {})} >,</option>
            </select>
          </Flex>
          <span style={{ marginBottom: 12 }}><em>Wijzig dit als het bestand niet goed ingeladen kan worden in Excel.</em></span>
          <div style={{ marginBottom: 12 }}>
            <Button onClick={this.loadExportData}>Genereer rapport</Button>
          </div>
          <div style={{ marginBottom: 12 }}>
            {
              this.state.exportData.length > 0 ?
                <CSVLink
                  className="ant-btn ant-btn-primary"
                  data={this.state.exportData}
                  filename={`orders-${this.state.exportStartDate.format('YYYY-MM-DD')}-${this.state.exportEndDate.format('YYYY-MM-DD')}.csv`}
                  headers={[
                    { label: "Ordernummer", key: "orderNr" },
                    { label: "Aantal producten", key: "products" },
                    { label: "Totaalprijs", key: "total" },
                    { label: "Betaald", key: "paid" },
                    { label: "Betaalmethode", key: "paymentMethod" },
                    { label: "Besteld op", key: "date" },
                    { label: "Ophalen op", key: "timeSlot" },
                    { label: "Besteld door", key: "customer" },
                  ]}
                  separator={this.state.separator}
                >
                  Download rapport
                </CSVLink> : ''
            }
          </div>
        </Flex>

      </Fragment>
    );
  }
}


const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
