import React, { Component, Fragment } from 'react';
import { Typography, List, Button, Icon } from 'antd';
import { withRouter } from "react-router-dom";
import ReactDragListView from "react-drag-listview";
import {
  getCategories,
  postCategoryOrder
} from "../lib/serverCommunication";
import Flex from "../components/layout/Flex";

const { Title, Text } = Typography;

class SortCategoriesPage extends Component {

  state = {
    categories: [],
    altered: false,
    saving: false,
  };

  componentDidMount() {
    this.refreshState();
  }

  refreshState = () => {
    getCategories()
      .then(({ products, error }) => {
        if (products) {

          const productCategories = products.sort((a, b) => {
            return (b.order > a.order ? -1 : 1);
          });
          this.setState({ categories: productCategories });
        }
      });
  };

  onDragEnd = (fromIndex, toIndex) => {
    if (toIndex < 0) {
      return;
    } // Ignores if outside designated area

    const items = [...this.state.categories];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    this.setState({ categories: items, altered: true });
  };

  saveCategoryOrder = async () => {
    this.setState({ saving: true });
    const order = [];
    for (let index in this.state.categories) {
      order.push(this.state.categories[index]._id);
    }
    await postCategoryOrder({ order });
    this.setState({ saving: false, altered: false });
  };

  getSelectedProductIds = (category) => {
    const { products } = this.state;
    return products
      .map((p, i) => ({ p, i }))
      .filter(o => o.p.type === category)
      .sort((oA, oB) =>
        oA.p.relativeOrder - oB.p.relativeOrder)
      .map(o => o.i)
  };

  render() {
    const { categories, saving, altered } = this.state;

    return (
      <Fragment>
        <Flex row justifyContent={'space-between'}>
          <Title>Categorievolgorde</Title>
        </Flex>
        {
          !saving ?
            <Fragment>
              <Flex>
                <Button type="primary" disabled={!altered} onClick={this.saveCategoryOrder}>Volgorde opslaan</Button>
              </Flex>
              <Flex>

                <ReactDragListView
                  nodeSelector=".ant-list-item"
                  onDragEnd={this.onDragEnd}
                >
                  <List className="product-list-sort">
                    {
                      categories.map((cat, i) => {
                        return (
                          <List.Item
                            key={i}
                            //actions={[(i > 0 ? '^' : undefined),(i < selectedProducts.length - 1 ? 'V' : undefined)]}
                            actions={[
                              <Button type="primary" shape="circle" disabled={i === 0} size="small"
                                onClick={() => this.onDragEnd(i, i - 1)}>↑</Button>,
                              <Button type="primary" shape="circle" disabled={i === categories.length - 1}
                                size={"small"} onClick={() => this.onDragEnd(i, i + 1)}>↓</Button>
                            ]}
                          >
                            <List.Item.Meta title={cat.name}/>
                          </List.Item>
                        )
                      })
                    }
                  </List>
                </ReactDragListView>
              </Flex>
              <Flex>
                <Text type="secondary">Tip: U kunt de categorieën verslepen met uw muis, of de pijltjes gebruiken om de
                  volgorde aan te passen.</Text>
              </Flex>
            </Fragment>
            : <Icon type="sync" spin style={{ fontSize: 32 }}/>}
      </Fragment>
    );
  }
}

export default withRouter(SortCategoriesPage);




