import { SET_LOCATIONS } from "../actions/Actions";

const initialState = {
  locations: null,
};

const settings = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SET_LOCATIONS:
    return {
      ...state,
      locations: action.payload
    }
  default:
    return state;
  }
};

export default settings;


