import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Pagination, Table, Typography } from "antd";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { getOrdersTable, getProducts } from "../lib/serverCommunication";
import Flex from "../components/layout/Flex";
import MainPageOrderModal from "../components/MainPageComponents/MainPageOrderModal";
import { setProducts } from "../redux/actions/Actions";
import { formatCurrency } from '../lib/formatter';

const { Title, Text } = Typography;

class MainPage extends Component {

  state = {
    orders: [],
    modalVisible: false,
    modalOrder: {},
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: true,
  };

  componentDidMount() {
    this.loadProducts().then(this.refreshStats);
  }

  componentWillUnmount() {
  }

  loadProducts = async () => {
    await getProducts()
      .then(({ products, error }) => {
        if (error) {

        }
        if (products) {
          this.props.setProducts(products);
        }
      })
  };

  refreshStats = async (page) => {
    this.setState({ loading: true });
    await getOrdersTable(page || this.state.pagination.current)
      .then((response) => {
        const orders = response.orders && response.orders.data ? response.orders.data : {};
        if (orders) {
          orders.forEach(order => {
            order.timeSlot = moment(order.timeSlot);
            order.date = moment(order.date);
            order.products.forEach(p => {
              if (p.options && Array.isArray(p.options) && p.options.length > 0) {
                const product = this.props.products.find(pFilter => pFilter._id === p._id);
                if (product) {
                  p.options.forEach((opt, index) => {
                    const option = product.options.find(o => o.key === opt.key);
                    p.options[index] = {
                      ...opt,
                      ...option
                    }
                  });
                }
              }
            });

            order.paid = false;
            order.paymentMethod = 'Bij afhalen';
            order.payment.forEach(payment => {
              if (order.paymentMethod !== 'contant') {
                order.paymentMethod = payment.method;
              }
              if (['paid', 'paidout'].includes((payment.status || '').toLowerCase())) {
                order.paid = true;
              }
            });
          });


          return this.setState({
            loading: false,
            orders: orders,
            pagination: { ...this.state.pagination, current: page || 1, total: response.orders.total || 100 }
          });
        }
      });

  };

  handleTableChange = (newCurrent, total) => {
    this.refreshStats(newCurrent);
  };

  renderOrders = () => {
    const { orders, pagination, loading } = this.state;

    const localOrders = [...orders].filter(order => order.status === 'published');
    if (!localOrders || localOrders.length === 0) {
      return null;
    }

    localOrders.sort((a, b) => {
      if (a.orderId < b.orderId) {
        return 1;
      }
      else {
        return -1;
      }
    });

    const columns = [
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, order) => {
          const status = order.finished ? 'Klaar' : (order.timeSlot.isBetween(moment().startOf('day'), moment().endOf('day')) ? 'Vandaag' : 'Later');
          return <span
            className={`order-list-status order-list-status-${status.toLowerCase()}`}>{status}</span>
        },
        filters: [{ text: 'Klaar', value: 'Klaar' }, { text: 'Vandaag', value: 'Vandaag' }, {
          text: 'Later',
          value: 'Later'
        }],
        onFilter: (value, order) => {
          const status = order.finished ? 'Klaar' : (order.timeSlot.isBetween(moment().startOf('day'), moment().endOf('day')) ? 'Vandaag' : 'Later');
          return status === value;
        }
      },
      {
        title: 'Betaalmethode',
        dataIndex: 'paymentMethod',
        key: 'paymentMethod',
        render: (text, order) => {
          return (<Text style={{
            fontWeight: 'bold',
            color: order.paid ? 'green' : 'red'
          }}>{order.paymentMethod}</Text>)
        },
      },
      {
        title: 'OrderNr',
        dataIndex: 'orderNr',
        key: 'orderNr',
        sorter: (a, b) => {
          if (a > b) {
            return 1;
          }
          else if (a < b) {
            return -1;
          }
          else {
            return 0;
          }
        },
      },
      {
        title: 'Ophalen',
        dataIndex: 'timeSlot',
        key: 'timeSlot',
        render: (value, record) => record.timeSlot.format('DD/MM HH:mm'),
        filters: Array.from(new Set(
          localOrders
            .sort((order, order2) => order2.timeSlot - order.timeSlot)
            .map(order => order.timeSlot.format('DD/MM'))
        )).map(val => ({ text: val, value: val })),
        onFilter: (value, record) => record.timeSlot.format('DD/MM') === value,
        sorter: ({ timeSlot: a }, { timeSlot: b }) => {
          if (a > b) {
            return 1;
          }
          else if (a < b) {
            return -1;
          }
          else {
            return 0;
          }
        },
      },
      {
        title: 'Besteld door',
        dataIndex: 'customer.firstName',
        key: 'customer.firstName',
        render: (value, record) => {
          if (record.userInfo && record.userInfo.firstName) {
            return `${record.userInfo.firstName || ''} ${record.userInfo.lastName || ''}`;
          }
          else if (record.customer) {
            return `${record.customer.firstName || ''} ${record.customer.lastName || ''}`;
          }
          return '';
        },
      },
      {
        title: '# Prod',
        dataIndex: 'products.amount',
        key: 'products.amount',
        render: (value, record) => record.products.reduce((acc, cur) => acc + cur.amount, 0),
      },
      {
        title: 'Totaal',
        dataIndex: 'products.total',
        key: 'products.total',
        render: (value, record) => formatCurrency(record.products.reduce((acc, cur) => {
          return acc + (cur.amount * (cur.price + cur.options.reduce((acc2, cur2) => acc2 + cur2.price, 0)))
        }, 0) || 0),
      },
      {
        title: 'Besteld op',
        dataIndex: 'date',
        key: 'date',
        render: (value, record) => record.date.format('DD/MM HH:mm'),
        filters: Array.from(new Set(
          localOrders
            .sort((order, order2) => order2.date - order.date)
            .map(order => order.date.format('DD/MM'))
        )).map(val => ({ text: val, value: val })),
        onFilter: (value, record) => record.date.format('DD/MM') === value,
        sorter: ({ date: a }, { date: b }) => {
          if (a > b) {
            return 1;
          }
          else if (a < b) {
            return -1;
          }
          else {
            return 0;
          }
        },
      },
      {
        title: 'Bekijk details',
        render: (text, record) => {
          return (
            <Button type='seconday' icon={'search'}
              onClick={() => this.props.history.replace(`orders/list/${record._id}`)}/>
          )
        }
      }
    ];

    if(this.props.locations) {
      columns.splice(1, 0,
        {
          title: 'Locatie',
          dataIndex: 'location',
          key: 'location',
          render: (value, record) => this.props.locations.find(l => l.key == record.location)?.name || record.location,
        }
      );
    }

    return (
      <>
        <Table
          dataSource={localOrders}
          pagination={false}
          loading={loading}
          columns={columns}
          style={{ overflowY: 'auto' }}
          rowKey="_id"
        />
        <Pagination showLessItems pageSize={10} total={pagination.total} onChange={this.handleTableChange}/>
      </>

    );
  };

  render() {
    const { modalOrder, modalVisible } = this.state;

    const renderedOrders = this.renderOrders();

    return (
      <Fragment>
        <Title>Bestellingen</Title>
        {
          renderedOrders ?
            renderedOrders :
            <Flex center>
              <Typography>Nog geen bestellingen ontvangen</Typography>
            </Flex>
        }
        <MainPageOrderModal
          order={modalOrder}
          handleClose={() => this.setState({ modalVisible: false })}
          visible={modalVisible}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  products: state.product.list,
  locations: state.settings.locations,
});
const mapDispatchToProps = dispatch => ({
  setProducts: products => dispatch(setProducts(products)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainPage));
