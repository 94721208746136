import React, { Component, Fragment } from 'react';
import {
  Typography,
  Radio,
  List,
  Button,
  Icon
} from 'antd';
import {
  Link,
  withRouter
} from "react-router-dom";
import ReactDragListView from "react-drag-listview";
import { getCategories, getProducts, patchProduct } from "../lib/serverCommunication";
import Flex from "../components/layout/Flex";

const { Title, Text } = Typography;


const limitLength = (text, maxLength) => {
  if (!text || text.length < maxLength) {
    return text;
  }
  const sub = text.substring(0, 100);
  return sub.substring(0, sub.lastIndexOf(' ')) + ' ...'
};

class SortProductsPage extends Component {

  state = {
    categories: [],
    products: [],
    selectedProducts: [],
    selectedCategory: null,
    altered: false,
    saving: false,
  };

  componentDidMount() {
    this.refreshState();
  }

  refreshState = () => {
    getProducts()
      .then(({ products, error }) => {
        if (products) {
          this.setState({ products });
        }
      });
    getCategories()
      .then(({ products, error }) => {
        if (products) {
          const productCategories = products.sort((a, b) => {
            return (b.order > a.order ? -1 : 1);
          });
          this.setState({ categories: productCategories });
        }
      });
  };

  onDragEnd = (fromIndex, toIndex) => {
    if (toIndex < 0) {
      return;
    } // Ignores if outside designated area

    const items = [...this.state.selectedProducts];
    const item = items.splice(fromIndex, 1)[0];
    items.splice(toIndex, 0, item);
    this.setState({ selectedProducts: items, altered: true });
  };

  saveProductOrder = async () => {
    this.setState({ saving: true });
    for (let index in this.state.selectedProducts) {
      const relativeIndex = this.state.selectedProducts[index];
      const product = this.state.products[relativeIndex];
      const prod = { _id: product._id, relativeOrder: index + 1 };
      await patchProduct(prod);
    }
    this.setState({ saving: false });
  };

  getSelectedProductIds = (category) => {
    const { products } = this.state;
    return products
      .map((p, i) => ({ p, i }))
      .filter(o => o.p.category._id === category)
      .sort((oA, oB) =>
        oA.p.relativeOrder - oB.p.relativeOrder)
      .map(o => o.i)
  };

  render() {
    const { categories, selectedCategory, saving, selectedProducts, altered } = this.state;

    return (
      <Fragment>
        <Flex row justifyContent={'space-between'}>
          <Title>Productvolgorde in categorie</Title>
        </Flex>
        <Flex>
          <Link to="/sort-categories" className="ant-btn ant-btn-primary" style={{ marginBottom: 20 }}>Naar categorieeën
            sorteren</Link>
        </Flex>
        {
          !saving ?
            <Fragment>
              <Flex>
                <Radio.Group value={selectedCategory} onChange={(event) => this.setState(
                  {
                    selectedCategory: event.target.checked ? event.target.value : null,
                    selectedProducts: event.target.checked ? this.getSelectedProductIds(event.target.value) : [],
                    altered: false
                  }
                )} buttonStyle="solid" style={{ marginBottom: 20 }}>
                  {
                    categories.map((cat, i) => <Radio.Button key={i} value={cat._id}>{cat.name}</Radio.Button>)
                  }
                </Radio.Group>
              </Flex>
              <Flex>
                <Button type="primary" disabled={!altered} onClick={this.saveProductOrder}>Volgorde opslaan</Button>
              </Flex>
              <Flex>

                <ReactDragListView
                  nodeSelector=".ant-list-item"
                  onDragEnd={this.onDragEnd}
                >
                  <List className="product-list-sort">
                    {
                      selectedProducts.map((p, i) => {
                        const product = this.state.products[p];
                        return (
                          <List.Item
                            key={i}
                            //actions={[(i > 0 ? '^' : undefined),(i < selectedProducts.length - 1 ? 'V' : undefined)]}
                            actions={[
                              <Button type="primary" shape="circle" disabled={i === 0} size="small"
                                onClick={() => this.onDragEnd(i, i - 1)}>↑</Button>,
                              <Button type="primary" shape="circle" disabled={i === selectedProducts.length - 1}
                                size={"small"} onClick={() => this.onDragEnd(i, i + 1)}>↓</Button>
                            ]}
                          >
                            <List.Item.Meta title={product.name}
                              avatar={product.photo ?
                                <img className="product-list-img" src={product.photo.httpPath}
                                  alt={'img'}/> : undefined}
                              description={limitLength(product.description, 100)}/>
                          </List.Item>
                        )
                      })
                    }
                  </List>
                </ReactDragListView>
              </Flex>
              <Flex>
                <Text type="secondary">Tip: U kunt de producten verslepen met uw muis, of de pijltjes gebruiken om de
                  volgorde aan te passen.</Text>
              </Flex>
            </Fragment>
            : <Icon type="sync" spin style={{ fontSize: 32 }}/>}
      </Fragment>
    );
  }
}

export default withRouter(SortProductsPage);
