import React, { Component, Fragment } from 'react';
import {
  Typography,
  Button,
  Icon,
  Card,
  Progress,
  Popconfirm,
  Modal,
  Select
} from 'antd';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import {
  genTimeSlots,
  getSettings,
  getTimeSlots,
  patchTimeSlot
} from "../lib/serverCommunication";
import Flex from "../components/layout/Flex";
import 'moment/locale/nl';
import SlotPopup from '../components/SlotPopup';

const { Title, Text } = Typography;


class TimeSlots extends Component {

  state = {
    timeslots: [],
    selectedWeek: null,
    saving: false,
    percentage: null,
    minimizedWeeks: [],
    timeSlotLength: '15',
    popup: {
      visible: false,
      x: 0,
      y: 0
    },
    defaultSlots: false
  };

  timeOut = null;

  componentDidMount() {
    this.refreshState();
    this.loadConfig();
  }

  componentWillUnmount() {
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
  }

  loadConfig = () => {
    getSettings().then(({ settings, error }) => {
      if (settings) {
        const defaultLength = settings.timeSlots.find(s => s.key === 'defaultLength');
        const defaultSlots = settings.timeSlots.find(s => s.key === 'defaultSlots');
        const state = {};
        if (defaultLength) {
          state.timeSlotLength = defaultLength.value;
        }
        if (defaultSlots) {
          state.defaultSlots = parseInt(defaultSlots.value) || 1;
        }

        this.setState(state);
      }
    })
  };

  refreshState = () => {
    this.setState({ saving: true });
    getTimeSlots()
      .then(({ timeslots, error }) => {
        if (timeslots) {
          const curDate = moment().startOf('isoWeek');
          const lastDate = moment().startOf('isoWeek').add(10, 'week');
          timeslots = timeslots.map(slot => {
            slot.startTime = moment(slot.startTime);
            return slot;
          });
          timeslots = timeslots.filter(slot => slot.startTime.isBetween(curDate, lastDate));
          this.setState({ timeslots, saving: false, percentage: null });
          this.timeOut = setTimeout(this.refreshState, 60 * 1000);
        }
      });
  };

  generateTimeSlots = async (date, resetAll = false, useStateLength = false) => {
    this.setState({ saving: true });
    await genTimeSlots({ date, resetAll, slotLength: useStateLength ? this.state.timeSlotLength : 15 });
    this.refreshState();
  };

  updateTimeSlot = ({ _id, slots }) => {
    const timeslots = this.state.timeslots;
    const timeSlot = timeslots.find(slot => slot._id === _id);
    if (timeSlot) {
      timeSlot.slots = slots;
      this.setState({ timeslots: timeslots });
    }
  };

  updateTimeSlotDay = async (date, slots) => {
    const startOfDay = date.clone().startOf('day');
    const endOfDay = date.clone().endOf('day');
    await this.updateTimeSlotRange(startOfDay, endOfDay, slots);
  };

  updateTimeSlotWeek = async (date, slots) => {
    const startOfWeek = date.clone().startOf('isoWeek');
    const endOfWeek = date.clone().endOf('isoWeek');
    await this.updateTimeSlotRange(startOfWeek, endOfWeek, slots);
  };

  updateTimeSlotRange = async (startDate, endDate, slots) => {
    this.setState({ saving: true, percentage: 0 });
    const timeslots = this.state.timeslots.filter(slot => slot.startTime.isBetween(startDate, endDate));
    let done = 0;
    for (let slot of timeslots) {
      const body = { _id: slot._id, slots };
      await patchTimeSlot(body);
      done++;
      this.setState({ percentage: Math.round((done / timeslots.length) * 100) });
    }
    this.refreshState();
  };

  render() {
    const { timeslots, saving, percentage, minimizedWeeks, timeSlotLength } = this.state;
    const { generateTimeSlots, updateTimeSlot } = this;
    const now = moment();
    const timeSlotsByWeek = timeslots.reduce((weeks, timeSlot) => {
      const week = timeSlot.startTime.week();
      if (!weeks[week]) {
        weeks[week] = [];
      }
      weeks[week].push(timeSlot);
      return weeks;
    }, {});
    return (
      <Fragment>
        <Flex column justifyContent={'space-between'}>
          <Title>Tijdsvakken per week</Title>
          <Text>Tijdsvakken in het blauw zijn beschikbaar, rood zijn uitgeschakeld. Klik om de knop om het tijdsvak in
            of uit te schakelen.</Text>
        </Flex>
        <Flex row wrap>
          {
            !saving ?
              [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(weeksAhead => {
                const startOfWeek = moment().startOf('week').add(weeksAhead, 'week');
                const endOfWeek = moment().startOf('week').add(weeksAhead, 'week').endOf('week');
                const week = endOfWeek.week();
                const slotsByDay = timeSlotsByWeek[week] && timeSlotsByWeek[week].length ?
                  timeSlotsByWeek[week]
                    .reduce((days, slot) => {
                      const day = slot.startTime.format('YYYY-MM-DDT12:00:00Z');
                      if (!days[day]) {
                        days[day] = [];
                      }
                      days[day].push(slot);
                      return days;
                    }, {}) : {};
                const minimized = minimizedWeeks.indexOf(week) > -1;
                return (
                  <Fragment key={weeksAhead}>
                    <Flex style={minimized ? { marginRight: 20 } : { width: '100%' }} row wrap>
                      <Flex row style={{ width: '100%' }} wrap>
                        <Card style={{ marginBottom: 20 }}>
                          <Flex row justifyContent={'space-between'} style={{ width: '100%' }} stretch wrap>
                            <Flex column style={{ padding: 20, marginRight: 20, position: 'relative' }}>
                              <div style={{ position: 'absolute', top: -20, left: -20 }}>
                                <Button
                                  size="small"
                                  type="control"
                                  icon={!minimized ? 'down' : 'right'}
                                  style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}
                                  onClick={() => {
                                    const minimizedWeeks = [...this.state.minimizedWeeks];
                                    if (minimized) {
                                      const index = minimizedWeeks.indexOf(week);
                                      if (index > -1) {
                                        minimizedWeeks.splice(index, 1);
                                      }
                                    }
                                    else {
                                      minimizedWeeks.push(week);
                                    }
                                    this.setState({ minimizedWeeks });
                                  }}
                                >{minimized ? 'Uitklappen' : 'Inklappen'}&nbsp;</Button>
                              </div>
                              <Text>Week</Text>
                              <Title style={{ marginTop: 20 }}>{week}</Title>
                              <div>
                                {
                                  !minimized && timeSlotsByWeek[week] ? (
                                    timeSlotsByWeek[week].some(slot => slot.slots > 0) ?
                                      <Flex column justifyContent="space-between">
                                        <Popconfirm
                                          title="Weet u zeker dat u alle tijdsvakken voor deze week wilt uitschakelen？"
                                          okText="Ja"
                                          cancelText="Nee"
                                          icon={<Icon type="poweroff"/>}
                                          onConfirm={() => this.updateTimeSlotWeek(startOfWeek, 0)}>
                                          <Button type="control" icon="poweroff">Alle tijdvakken uitschakelen</Button>
                                        </Popconfirm>
                                        <Button type="danger" icon="sync" onClick={() => {
                                          Modal.confirm({
                                            title: 'Weet u zeker dat u alle tijdsvakken voor deze week opnieuw wilt genereren？',
                                            icon: <Icon type="sync" spin/>,
                                            content: <Flex column justifyContent="space-between">
                                              <Text>Kies de lengte van de tijdsloten</Text>,
                                              <Select onChange={(value) => this.setState({ timeSlotLength: value })}
                                                defaultValue={timeSlotLength}>
                                                <Select.Option value="5">5 minuten</Select.Option>
                                                <Select.Option value="10">10 minuten</Select.Option>
                                                <Select.Option value="15">15 minuten</Select.Option>
                                                <Select.Option value="20">20 minuten</Select.Option>
                                                <Select.Option value="30">30 minuten</Select.Option>
                                              </Select>
                                            </Flex>,
                                            onOk() {
                                              generateTimeSlots(startOfWeek, true, true).then()
                                            },
                                            onCancel() {
                                            },
                                          });
                                        }}>Alle tijdvakken opnieuw genereren</Button>
                                      </Flex> :
                                      <Button type="primary" icon="poweroff"
                                        onClick={() => this.updateTimeSlotWeek(startOfWeek, 1)}>Alle tijdvakken
                                        inschakelen</Button>
                                  ) : null
                                }
                              </div>
                            </Flex>
                            {!minimized ?
                              <Flex basis={'60%'}
                                grow={1} column style={{ padding: 20 }}>
                                {
                                  Object.keys(slotsByDay).length > 0 ?
                                    Object.keys(slotsByDay).map((date, index) => {
                                      const day = moment(date).format('dddd DD MMMM');
                                      return <div style={{ marginBottom: 8 }} key={index}>
                                        <Flex row style={{ marginBottom: 4 }}>
                                          <Text strong>{day}</Text>
                                          <div style={{ marginLeft: 20 }}>
                                            {
                                              slotsByDay[date] ? (
                                                slotsByDay[date].some(slot => slot.slots > 0) ?
                                                  <Popconfirm
                                                    title="Weet u zeker dat u alle tijdsvakken voor deze dag wilt uitschakelen"
                                                    okText="Ja"
                                                    cancelText="Nee"
                                                    icon={<Icon type="poweroff"/>}
                                                    onConfirm={() => this.updateTimeSlotDay(moment(date), 0)}>
                                                    <Button type="control" icon="poweroff"
                                                      size="small">Uitschakelen</Button>
                                                  </Popconfirm> :
                                                  <Button type="control" icon="poweroff" size="small"
                                                    onClick={() => this.updateTimeSlotDay(moment(date), 1)}>Inschakelen</Button>
                                              ) : null
                                            }
                                          </div>
                                        </Flex>
                                        <Flex row wrap>
                                          {
                                            (slotsByDay[date] || []).map((slot, index) => {
                                              slot.updateAvailable = async (change) => {
                                                const body = { _id: slot._id, slots: slot.slots + change };
                                                updateTimeSlot(body);
                                                await patchTimeSlot(body)
                                              };
                                              return <Button
                                                key={index}
                                                disabled={slot.startTime < now}
                                                type={slot.slots > 0 ? (slot.used > 0 ? 'ghost' : 'primary') : 'danger'}
                                                onClick={async () => {
                                                  if (!(slot.used >= slot.slots && slot.slots > 0)) {
                                                    await slot.updateAvailable(slot.slots > 0 ? (0 - slot.slots) : this.state.defaultSlots);
                                                  }
                                                }}
                                                style={{ margin: 2 }}
                                                dataSlots={slot.slots}
                                                dataUsed={slot.used}
                                                onContextMenu={event => {
                                                  event.preventDefault();
                                                  if (!this.state.visible) {
                                                    const that = this;
                                                    document.addEventListener(`click`, function onClickOutside() {
                                                      that.setState({ popup: { visible: false } });
                                                      document.removeEventListener(`click`, onClickOutside)
                                                    })
                                                  }
                                                  this.setState({
                                                    popup: {
                                                      slot,
                                                      visible: true,
                                                      x: event.clientX + window.scrollX,
                                                      y: event.clientY + window.scrollY
                                                    }
                                                  })
                                                }}
                                              >{slot.startTime.format('HH:mm')}</Button>;
                                            })
                                          }
                                        </Flex>
                                      </div>
                                    }) :
                                    <div><Button type="primary" size="large" onClick={() => {
                                      return this.generateTimeSlots(moment().startOf('week').add(weeksAhead, 'week').add(12, 'hour').format('YYYY-MM-DDTHH:mm:ssZ'))
                                    }}>Tijdsvakken toevoegen aan week</Button></div>
                                }
                              </Flex> : null
                            }
                          </Flex>
                        </Card>
                      </Flex>
                    </Flex>
                  </Fragment>
                )
              })
              : <Flex center style={{ margin: 20, width: '100%' }}>
                {
                  percentage === null ?
                    <Icon type="sync" spin style={{ fontSize: 32 }}/> :
                    <Progress type="circle" percent={percentage}/>
                }
              </Flex>}
        </Flex>
        <SlotPopup {...this.state.popup}/>
      </Fragment>
    );
  }
}

export default withRouter(TimeSlots);
