import React, {useCallback, useEffect, useState} from 'react';
import 'antd/dist/antd.css';
import './App.scss';
import {Provider, useDispatch, useSelector} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {BrowserRouter as Router, Redirect, Route, Switch, useLocation} from "react-router-dom";
import {Icon} from 'antd';
import DefaultLayout from "./layout/DefaultLayout";
import {persistor, store} from './redux/configureStore';
import MainPage from "./pages/MainPage";
import Login from "./pages/Login";
import UsersPage from "./pages/UsersPage";
import ProductsPage from "./pages/ProductsPage";
import ProductEditPage from "./pages/ProductEditPage";
import ProcessOrderPage from "./pages/ProcessOrderPage";
import SortProductsPage from "./pages/SortProductsPage";
import Orders from "./pages/Orders";
import Flex from "./components/layout/Flex";
import {logoutUser, setLocations} from "./redux/actions/Actions";
import {getProfile, getSettings} from "./lib/serverCommunication";
import TimeSlots from './pages/TimeSlots';
import SettingsPage from './pages/Settings';
import StatisticsPage from './pages/StatisticsPage';
import SortCategoriesPage from './pages/SortCategoriesPage';
import ScreensPage from './pages/Screens';
import ScreenPage from './pages/Screen';
import config from "./lib/config";
import {Helmet} from "react-helmet";

function AuthRoutes() {
    const [verifyingAuth, updateVerifyingAuth] = useState(true);
    const location = useLocation();
    const auth = useSelector((state) => state.auth)
    const dispatch = useDispatch();

    const logout = useCallback(() => dispatch(logoutUser()), [auth, dispatch])

    useEffect(() => {
        if (!auth.jwt) {
            return;
        }
        getProfile()
            .then(body => {
                if (body.user && body.user.roles && Array.isArray(body.user.roles) && body.user.roles.includes('admin')) {
                    return updateVerifyingAuth(false);
                } else {
                    return logout();
                }
            })
        getSettings()
          .then(({ settings, error }) => {
              if(settings.locations && settings.locations.length){
                dispatch(setLocations(settings.locations));
              } else {
                  dispatch(setLocations(null));
              }
          });
    }, [auth]);

    if (!auth.jwt) {
        return (
            <Redirect
                to={{
                    pathname: "/login",
                    state: {from: location}
                }}
            />
        )
    }

    if (verifyingAuth) {
        return (
            <DefaultLayout verifying>
                <Flex center style={{width: '100%', height: '100%'}}>
                    <Icon type={'loading'} spin style={{fontSize: '3rem'}}/>
                </Flex>
            </DefaultLayout>
        )
    }

    return (
        <Router>
            <DefaultLayout>
                <Switch>
                    <Route exact path="/">
                        <MainPage/>
                    </Route>
                    <Route path="/orders/dashboard/:order_id">
                        <ProcessOrderPage/>
                    </Route>
                    <Route path="/orders/list/:order_id">
                        <ProcessOrderPage/>
                    </Route>
                    <Route exact path="/orders">
                        <Orders/>
                    </Route>
                    <Route path="/users">
                        <UsersPage/>
                    </Route>
                    <Route path="/timeslots">
                        <TimeSlots/>
                    </Route>
                    <Route exact path="/products">
                        <ProductsPage/>
                    </Route>
                    <Route exact path="/sort">
                        <SortProductsPage/>
                    </Route>
                    <Route exact path="/sort-categories">
                        <SortCategoriesPage/>
                    </Route>
                    <Route exact path="/stats">
                        <StatisticsPage/>
                    </Route>
                    <Route exact path="/settings">
                        <SettingsPage/>
                    </Route>
                    <Route exact path="/products/new">
                        <ProductEditPage/>
                    </Route>
                    <Route path="/products/:id">
                        <ProductEditPage/>
                    </Route>
                    <Route exact path="/screens">
                        <ScreensPage/>
                    </Route>
                    <Route path="/screens/:id">
                        <ScreenPage/>
                    </Route>
                </Switch>
            </DefaultLayout>
        </Router>
    )
}

function App() {
    return (
        <>
            <Helmet>
                <title>{config.siteName}</title>
            </Helmet>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Router>
                        <Switch>
                            <Route path="/login">
                                <Login/>
                            </Route>
                            <Route path="/">
                                <AuthRoutes/>
                            </Route>
                        </Switch>
                    </Router>
                </PersistGate>
            </Provider>
        </>
    );
}

export default App;
